/* eslint-disable */
var icon = require('vue-svgicon');
icon.register({
    'window-split': {
        width: 16,
        height: 16,
        viewBox: '0 0 16 16',
        data: '<path pid="0" d="M2.5 4a.5.5 0 100-1 .5.5 0 000 1m2-.5a.5.5 0 11-1 0 .5.5 0 011 0m1 .5a.5.5 0 100-1 .5.5 0 000 1"/><path pid="1" d="M2 1a2 2 0 00-2 2v10a2 2 0 002 2h12a2 2 0 002-2V3a2 2 0 00-2-2zm12 1a1 1 0 011 1v2H1V3a1 1 0 011-1zM1 13V6h6.5v8H2a1 1 0 01-1-1m7.5 1V6H15v7a1 1 0 01-1 1z"/>',
    },
});
